<template>
    <div class="timeFrom_box">
       <div class="task_box pr">
           <ul>
               <li 
                v-for="item of taskList"
                :key="item.name"
                @click="navChange(item.code)"  
                :class="[active == item.code? 'activebg baseColor' : '']">{{ item.name }}</li>
           </ul>
           <div class="piont_box"></div>
           <HnRemarks class="remarks_box" width= '240'>
              <div class="remarks_content_box">
                <p>
                  <span class="baseColor">循环模式：</span>
                   有效期内，在选择的日期中，按时间间隔循环执行控制动作，并可延时动作。
                </p>
                <p>
                  <span class="baseColor">定时模式：</span>
                  有效期内，在选择的日期中，按设定时间执行控制动作，并可延时动作。
                </p>
              </div>
           </HnRemarks>
       </div>
       <div class="from_box">
           <el-form :model="ctlForm" :rules="rules" ref="ctlForm" size="small" label-width="100px" class="demo-ruleForm">
            <el-form-item label="有效期" prop="time">
                    <el-date-picker
                    v-model="ctlForm.time"
                    type="daterange"
                    align="right"
                    value-format="yyyy-MM-dd"
                    format="yyyy 年 MM 月 dd 日"
                    unlink-panels
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    >
                    </el-date-picker>
            </el-form-item>
            <el-form-item label="时间选择" prop='week'>
                <week v-model="ctlForm.week"></week>
            </el-form-item>
            <el-row>
              <el-col :span="10">
                  <transition name="fade" mode="out-in">
                        <template v-if="active">
                            <el-form-item label="执行操作" prop="loopTime">
                                <el-input
                                    type="number"
                                    class="input_box"
                                    v-model.number="ctlForm.loopTime">
                                    <span slot="suffix" class="unit_box">分钟</span>
                                </el-input>
                            </el-form-item>
                        </template>
                        <template v-else>
                            <el-form-item label="执行操作" prop="implementTime">
                                <el-time-picker
                                    v-model="ctlForm.implementTime"
                                    placeholder="时间"
                                    @change="timeChange"
                                    value-format="HH:mm"
                                    format="HH:mm"
                                    style="width:100%"
                                >
                                </el-time-picker>
                            </el-form-item>
                        </template>
                    </transition>
              </el-col>
              <el-col :span="14">
                   <el-form-item prop="controlAction" label-width= '10px'>
                        <el-radio-group v-model="ctlForm.controlAction">
                            <template v-if="isReversible">
                            <el-radio :label="2">关</el-radio>
                            <el-radio :label="1">开</el-radio>
                            <el-radio :label="0">暂停</el-radio>
                            </template>
                            <template v-else>
                            <el-radio :label="0">关</el-radio>
                            <el-radio :label="1">开</el-radio>
                            </template>
                            
                        </el-radio-group>
                    </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="10">
                     <el-form-item label="延时操作">
                        <el-input
                            type="number"
                            class="input_box"
                            v-model.number="ctlForm.delay">
                            <span slot="suffix" class="unit_box">分钟</span>
                        </el-input>
                    </el-form-item>
              </el-col>
              <el-col :span="14">
                     <el-form-item label-width= '10px'>
                        <el-radio-group v-model="ctlForm.delayAction">
                            <template v-if="isReversible">
                            <el-radio :label="2">关</el-radio>
                            <el-radio :label="1">开</el-radio>
                            <el-radio :label="0">暂停</el-radio>
                           
                            </template>
                            <template v-else>
                            <el-radio :label="0">关</el-radio>
                            <el-radio :label="1">开</el-radio>
                          
                            </template>
                        </el-radio-group>
                        <el-button size="mini" type="primary" @click="delayDel">取消</el-button>
                    </el-form-item>
              </el-col>
            </el-row>
            <el-form-item label="状态">
                <el-switch
                v-model="ctlForm.status"
                :active-value="1"
                :inactive-value="2"
                active-color="#00C395"
                inactive-color="#343F48">
                </el-switch>
                <span :class="['switch_text',ctlForm.status == 1 ? 'baseColor':'']">{{ ctlForm.status == 1 ? '启用':'停用' }}</span>
            </el-form-item> 
            <el-form-item class="but_box">
                <el-button type="warning" plain @click="resetForm('ctlForm')" size="mini" icon="el-icon-close">取消</el-button>
                <el-button type="primary" plain @click="submitForm('ctlForm')" size="mini" icon="el-icon-check">保存</el-button>
            </el-form-item> 
           </el-form> 
       </div>
       <HnSyncLoading :loading="perLoading" :erroStatus='erroStatus' width='80' types="line"/>
   
  </div>
</template>

<script>
import week from '@/components/localComponent/week.vue'
// import timeSelect from '@/components/timeSelect.vue'
import cronMixin from './inputCron/cronMixin'
import { deepClone } from '@/libs/util'
export default {
  mixins: [cronMixin],
  components: {
    week,
  },
  props: {
    devInfo: Object,
    planInfo: Object,
  },
  data () {
    const arrayEmpty = (rule, value, callback) => {
      if (!Array.isArray(value)) callback(new Error('数据格式为数组'))
      if (value.length) {
        callback()
      } else {
        callback(new Error('请选择日期'))
      }
    }
    return {
      active: true,
      taskList: [
        {
          name: '循环模式',
          code: true
        },
        {
          name: '定时模式',
          code: false
        },
      ],
      ctlForm: {
        id: '',
        time: '',
        implementTime: '',
        loopTime: null,
        week: [],
        actuatorIds: [], // 执行器id 
        isLoop: true,  // 是否循环
        status: '',   // 状态 1 启用 2 停用
        sTime: '',   // 有效开始日期
        eTime: '',   // 有效结束日期
        controlAction: '', // 控制动作
        cron: '',  // 表达式
        delayAction: '', //延时动作
        delay: null, // 延时时间
        delayUnit: '2' // 延时时间单位
      },
      erroStatus: false,
      perLoading: false,
      checkTime: '3',
      rules: {
        time: [
          { required: true, message: '请输入有效期', trigger: 'blur' },
        ],
        week: [
          { validator: arrayEmpty, trigger: 'blur', required: true }

        ],
        loopTime: [
          { required: true, message: '请选择执行频率', trigger: 'blur' },
        ],
        implementTime: [
          { required: true, message: '请选择执行时长', trigger: 'blur' },
        ],
        controlAction: [
          { required: true, message: '请选择执行动作', trigger: 'blur' },
        ]
      },
      // minutesText:'',
      // hoursText:'',
      // delayTimeType: '3',
      ctlTimeType: '3'
    }
  },
  computed: {
    isReversible () {
      return this.devInfo && this.devInfo.isReversible ? true : false
    },
    isEdit () {
      return !!this.planInfo
    }
  },
  watch: {

    planInfo (val) {

      if (val) this.analysisData(val)
    }
  },
  created () {
    if (this.planInfo) this.analysisData(this.planInfo)
  },
  methods: {
    navChange (code) {
      this.active = code
    },
    timeChange (data) {
      if (data && data.length) {
        let hour = [], minutes = []
        data.forEach(str => {
          let index = str.indexOf(':')
          let mData = str.slice(index + 1)
          if (mData.indexOf('0') === 0) {
            mData = mData.slice(1)
          }
          hour.push(str.slice(0, index))
          minutes.push(mData)
        })
        this.minutesText = minutes[0] + "-" + minutes[1]
        this.hoursText = hour[0] + "-" + hour[1]
      }
    },
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let data = this.mergeData()
          if (this.isEdit) {
            data.planId = this.planInfo.id
            this.reqEditIotControlPlan(data)

          } else {
            this.reqAddIotControlPlan(data)
          }


        } else {
  
          return false;
        }
      });
    },
    // 新增
    async reqAddIotControlPlan (data) {
      this.perLoading = true
      const res = await this.$api.AddIotControlPlan(data)
      if (res) {
        setTimeout(() => {
          this.perLoading = false
          this.devInfo.visbise = false
        }, 1000)
      } else {
        this.erroStatus = true
      }
    },
    // 编辑
    async reqEditIotControlPlan (data) {
      this.perLoading = true
      const res = await this.$api.EditIotControlPlan(data)
      if (res) {
        setTimeout(() => {
          this.perLoading = false
          this.devInfo.visbise = false
        }, 1000)
      } else {
        this.erroStatus = true
      }
    },
    delayDel(){
          this.$set(this.ctlForm,'delayAction','')
          this.$set(this.ctlForm,'delay', null)
    },
    // 整合请求数据
    mergeData () {
      let isLoop = this.active
      let ctlForm = this.ctlForm
      let cloneData = deepClone(ctlForm)
      // 转化周数据    
      this.weeksDataToCron(ctlForm.week)
      if (isLoop) {
        // 转化时分秒数据
        this.SMHDataToCron(ctlForm.loopTime)
      }

      // 获取赋值 cron 数据
      cloneData.cron = this.cronStr
      //   // 处理延时数据
      //   if(ctlForm.delay)cloneData.delayUnit = parseInt(this.delayTimeType)

      // 处理开始结束日期
      let timeArr = ctlForm.time
      cloneData.sTime = timeArr[0]
      cloneData.eTime = timeArr[1]
      // 处理状态
      //   cloneData.status = ctlForm.status? 1 : 2
      //  执行器id 赋值
      cloneData.actuatorIds = [this.devInfo.id]
      //  是否为循环任务
      cloneData.isLoop = isLoop
      return cloneData
    },
    // 解析数据
    analysisData (val) {
      let { isLoop, eTime, sTime, cron } = val
      Object.assign(this.ctlForm, val)
      //  转换循环任务    
      this.active = isLoop
      //  日期显示
      let eT = eTime.slice(0, eTime.indexOf(' '))
      let sT = sTime.slice(0, sTime.indexOf(' '))
      this.$set(this.ctlForm, 'time', [sT, eT])
      // 解析周
      let week = this.weeksCronToData(cron)
      this.$set(this.ctlForm, 'week', week)
      // 解析时间
      let cronToData = this.MHCronToData(cron)
      if (cronToData && typeof cronToData == 'number')
        this.$set(this.ctlForm, 'loopTime', parseInt(cronToData))
      else if (cronToData && typeof cronToData == 'string')
        this.$set(this.ctlForm, 'implementTime', cronToData)

    },
    resetForm (formName) {
      this.$refs[formName].resetFields();
      this.devInfo.visbise = false

    },
    timeChange (data) {
      if (data) {
        let dataList = data.split(':')
        this.minutesText = dataList[1]
        this.hoursText = dataList[0]
      }
    },

  }
}
</script>

<style lang="scss" scoped>
.task_box {
  width: 62%;
  height: 36px;
  margin: 0 auto 20px auto;
  & > ul {
    width: 100%;
    height: 100%;
    border: 1px solid #344552;
    background-color: #202e37;
    display: flex;
    border-radius: 5px;
    font-size: 14px;
    & > li {
      flex: 1;
      height: 100%;
      text-align: center;
      line-height: 36px;
    }
    .activebg {
      background-color: #344552;
    }
  }
  .piont_box {
    width: 14px;
    height: 14px;
    border-radius: 100%;
    border: 3px solid #202e37;
    background-color: #344552;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .remarks_box{
      position: absolute;
      right: -25px;
      top: 50%;
      margin-top: -9px;
  }
}
.from_box {
  width: 100%;
  //  height: 480px;
  .demo-ruleForm {
    width: 100%;
    .but_box {
      text-align: right;
      margin-bottom: 0 !important;
    }
  }
  .input_box ::v-deep .el-input__inner {
    text-align: center;
  }
  .unit_box {
    margin-right: 5px;
  }
  //  & > div{
  //      height: 100%;
  //  }
}
.switch_text {
  margin-left: 12px;
  color: #999;
}
.el-radio-group ::v-deep .el-radio{
   margin-right: 20px !important;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.el-date-editor{
  border-radius: 3px;
}
.remarks_content_box{
  & > p:last-child{
    margin-top: 14px;
  }
}
</style>