<template>
    <div class="control_main_box wh100">
        <HnContent :hasData='hasData'>
           <template slot="left">
                <HnMenu :menuDevType='menuDevType'  @change='devChange'></HnMenu>
           </template>
           <template slot="right">
                 <div class="wh100 boxBg right_content_box">
                    <div class="tools_box">
                        <div>
                            <el-input
                                placeholder="查询执行器名称"
                                v-model="devNameVal"
                                prefix-icon="el-icon-search"
                                @clear="clearSearch"
                                @input="devNameSearch"
                                class="devSearchIpt"
                                size="small"
                                clearable>
                            </el-input>
                            <template v-if="devStatus && ctlDevList.length"> 
                                <el-button type="success" plain size="small" @click="allCtl(1)">全部开启</el-button>
                                <el-button type="danger" plain size="small" @click="allCtl(2)">全部关闭</el-button>
                                <el-button type="warning" plain size="small" @click="allCtl(0)">全部暂停</el-button>
                            </template>
                        </div>
                        <div class="baseColor">
                            <span :class="['iconfont', devIcon]"></span>
                            <span>{{ devName }}</span>
                            <template v-if="!devStatus">
                                <el-alert
                                    :title="devStatusText"
                                    type="warning"
                                    class="alert_box"
                                    :closable= 'false'
                                    show-icon>
                                </el-alert>
                            </template>
                        </div>
                        <div>
                            <!-- <span class="el-icon-setting"></span>
                            <span>分组/控制计划</span> -->
                        </div>
                    </div> 
                    <div class="ctl_dev_box pageBg">
                        <template v-if="ctlDevList.length">
                                <ul class="boxScroll">
                                    <li v-for="(item,i) of ctlDevList" :key="item.id" :class="isActiveClass(item)">
                                    <div class="top_box boxBg">
                                        <div>
                                            <span :class="[isOnline == onlineData ? 'active' : isOnline == 2? 'standbyBg': 'offLineBg']"></span>
                                            <span>{{ item.name }}</span>
                                        </div>
                                        <el-popover
                                            class="ctl_popover"
                                            :placement="computLR(i)"
                                            v-model="item.visbise"
                                            :popper-class="computLRClass(i)"
                                            :offset='computLROffset(i)'
                                            width="500"
                                            trigger="click">
                                            <autoCtl :info='item' v-if="item.visbise"></autoCtl>
                                            <div class="cp iconfont icon-kongzhi baseColor" slot="reference"></div>
                                        </el-popover>
                                    </div>
                                    <div class="content_box boxBg">
                                        <div>
                                            <template v-if="item.channelStatus == 1 && item.workIcon">
                                                <img :src="gifUrl(item.workIcon)" alt="">
                                            </template>
                                            <template v-else>
                                                <span :class="[ item.isReversible && item.channelStatus == 0? 'baseColor' : '',item.icon] "></span>
                                            </template>
                                            
                                        </div>
                                        <div>
                                            <div 
                                                :class="['cp', item.channelStatus == 1 ? 'active activeText': '']" 
                                                @click="ctlOnOff(item,1)">开</div>
                                            <template v-if="item.isReversible">
                                                <div 
                                                :class="['stop_box', 'cp', item.channelStatus == 0 ? 'active activeText': '']" 
                                                @click="ctlOnOff(item,0)">停</div>
                                            </template>
                                            <div 
                                                :class="['cp', !isOpenDev(item) ? 'active activeText': '']" 
                                                @click="ctlOnOff(item,2)">关</div>
                                        
                                        </div>
                                    </div>
                                    </li>
                                    <template v-for="(ele,index) of supplyBox" >
                                        <li class="supplyBox" :key="index+'qwe'"></li>
                                    </template>
                                </ul>
                        </template>
                        <template v-else>
                                <hn-emptyData text= '执行器暂无数据' size= '180px'></hn-emptyData>
                            </template>
                    </div>
                        <HnSyncLoading 
                        :loading="perLoading" 
                        :erroStatus= 'erroStatus'
                        types="line"/>
                 </div>
           </template>
        </HnContent>    
        <getPwd
          :isShow.sync='isPwdShow'
          :ctlData='ctlData'
          @onOk='getPwd'
        ></getPwd>  
    </div>
</template>

<script>
    import config from '@/config'
    import { 
        devCodeAndId,
        imgAddHost,
        completionRow,
        debounce 
    }from '@/libs/util'
    import autoCtl from './components/autoCtl.vue'
    import getPwd from '@/components/localComponent/getPwd.vue'
    import menuFun from '@/mixins/menuFun'
    import ctrPwdMixin from '@/mixins/ctrPwd'
    let _query = debounce((vm, callBack) => {
        vm.queryFunc(callBack);
    }, 500);
    export default {
        components:{
            autoCtl,
            getPwd,
        },
        mixins: [ctrPwdMixin,menuFun],
        data() {
            return {
                menus:[],
                ctlDevList:[],
                supplyBox: [],
                ctlFormInfo:{
                    name:'',
                    devId:'',
                    devCode:''
                },
                perLoading: false,
                devNameVal:'',
                activeDevInfo: null,
                erroStatus: false,
                devInfo: null,
                noDataUrl: require('@/assets/imgs/noData/no_data2.png'),
                onlineData: config.devStatus.online,
                ctlData: null,
                isPwdShow: false,
                isAloneCtl: true,
                menuDevType: ['控制设备','采控设备'],
            }
        },
        created() {
            let supplyArr = completionRow(this.ctlDevList,5)
            this.supplyBox = supplyArr
        },
        computed:{
           isOnline() {
               let info = this.devInfo
          
               return info ? info.onlineStatus : false
           },
           gifUrl(){
              return item => {
                 return  imgAddHost(item)
               }
           },
           devName(){
              let data = this.activeDevInfo
          
              return data ?  data.devName : ''
           },
           devIcon(){
             let data = this.activeDevInfo
             return data && data.iconCls  ?  data.iconCls : 'icon-qixiangzhan_'
           },
           devStatus(){
              let data = this.activeDevInfo
           
              return data && data.onlineStatus !== this.onlineData ? false : true
           },
           devStatusText(){
               let data = this.activeDevInfo
               return data && data.onlineStatus == 0? '设备离线中，无法操作！' : '设备待机中，无法操作！'
           },
           isOpenDev(){
               return item => {
                //    return item.isReversible && item.channelStatus !== 2 ? 
                //           true : !item.isReversible && item.channelStatus == 1 ? true : false 
                   return item.channelStatus == 1 || (item.isReversible && item.channelStatus == 0 ) ? true : false           
               }
           },
           hanldeStopText(){
               return item => {
                  return  item.channelStatus == 0 ? '点击开启执行器': '点击暂停执行器' 
               }
           },
           isActiveClass(){
                   return item => {
                        if( item.isReversible ){
                          return  item.channelStatus == 0 || item.channelStatus == 1 ? 'ctr_box_border' : ''
                        }else{
                          return  item.channelStatus !== 1 ? '' : 'ctr_box_border' 
                        }
               }
           },
           computResult(){
                return i => {
                   let result = i < 5 ? i : i%5
                   return result == 3 || result == 4? true : false
                }
           },
           computLR(){
               return i => {
                  let result = this.computResult(i)
                  return result ? 'left': 'right'
               }
           },
           computLRClass(){
                return i => {
                   let result = this.computResult(i)
                   return result ? 'formLeftPopper': 'formRightPopper'
                }
           },
           computLROffset(){
                return i => {
                   let result = this.computResult(i)
                   return result ? -100 : 100
                }
           },
          
        },
        methods:{
            query( c ) {
               _query(this,c);
            },
            queryFunc( c ) {
                c()
            },
            isNeedPwd(){ 
              let { isSetPwd, devId } = this.devInfo
      
              let result = false
              if( isSetPwd ){
                  result = this.isPwdPass(devId)  // 验证设备密码是否存在或过期
                  result = !result   
              }
              return result
            },
            // 设备切换
            devChange(infos) {
                let data = infos[0]
                this.devInfo = this.getDevInfo(infos)
                this.ctlFormInfo = Object.assign({},this.ctlFormInfo,data)
                this.reqIotDeviceControlList() 
                this.activeDevInfo= this.handleActiveDev(data.devId)
            },
            intPlan(item){
                let data = {
                       devId: item.devId,
                       devCode: item.id
                }
                let result = devCodeAndId(data)
                this.$router.push({
                    path: '/intelligentCtl',
                    query: {
                        data: result
                    }
                })   
            },
          
            // 设备执行器查询
            async reqIotDeviceControlList(){
                const res = await this.$api.IotDeviceControlList(this.ctlFormInfo)
                const isData = res && res.length
                if(isData) res.forEach(item => item.visbise = false)
                this.ctlDevList = isData ? res : []
               
            },
            // 设备控制
            async reqIotDeviceCtrlChannel(data){
                try{
                   const res = await this.$api.IotDeviceCtrlChannel(data)
                   return res
                }catch{
                     return false
                }
               
               
            },
            ctlOnOff(item,code){
              
               if(this.isOnline !== 9){
                   this.$msg.info('设备离线中，无法操作')
                   return
               } 
               if(item && this.devStatus){ 
                    let onOrOff = code == 2 && !item.isReversible ? 0 : code
                    let data = {
                        id: item.devId,
                        actuatorId: item.id,
                        devCode: item.devCode,
                        no: item.channelNo,
                        reversalNo: item.reverseChannelNo,
                        stopNo: item.closeChannelNo,
                        openType: onOrOff,
                        exeNo: item.actuatorCode
                    }
                    
                    if(this.isNeedPwd()){
                        this.ctlData= data
                        this.isPwdShow = true   
                        this.isAloneCtl= true
                    }else{
                        let pwd = this.getPwdVal(this.devInfo.devId)
                        if(pwd) data.ctrlPwd = pwd
                        this.reqCtl(data)
                    }
                }
              
              
            },
            // 设置密码
            getPwd(data){
                if(this.isAloneCtl)
                   this.reqCtl(data)
                else  this.reqIotDeviceCtrlMany(data)  
            },
            //  设备批量控制
            async reqIotDeviceCtrlMany(data){
                this.perLoading = true
                const res = await this.$api.IotDeviceCtrlMany(data)
                if(res){
                    this.addLocaCtrPwd(this.devInfo.devId,data.ctrlPwd)
                    this.$set(this.ctlFormInfo,'name','')   
                    this.reqIotDeviceControlList() 
                    setTimeout(()=>{
                        this.perLoading = false
                    },300)
                }else{
                   this.erroStatus = true
                }  
                
                 
            },
            // 批量控制
            allCtl(code){
                let actuators = []
                if(this.isOnline !== 9){
                   this.$msg.info('设备离线中，无法操作')
                   return
               }
                this.ctlDevList.forEach(item => {
                   
                     switch (code) {
                        case 0:
                            if(item.isReversible){
                               actuators.push({
                                 actuatorId: item.id,
                                 openType: 0
                               })
                            }
                            break;
                        case 1:
                            actuators.push({
                                actuatorId: item.id,
                                openType: 1
                            })
                            break;
                        case 2:
                            if(item.isReversible){
                               actuators.push({
                                 actuatorId: item.id,
                                 openType: 2
                               })
                            }else{
                               actuators.push({
                                 actuatorId: item.id,
                                 openType: 0
                               })
                            }
                            break;
                     }

                })
                let data = { actuators }
                if(this.isNeedPwd()){
                    this.ctlData= data
                    this.isPwdShow = true   
                    this.isAloneCtl= false
                }else{
                    let pwd = this.getPwdVal(this.devInfo.devId)
                    if(pwd) data.ctrlPwd = pwd
                    this.reqIotDeviceCtrlMany(data)
                }  
            },
            // 请求控制
            async reqCtl(data){
            

                   this.perLoading = true
                   if(this.erroStatus) this.erroStatus = false
                   const res = await this.reqIotDeviceCtrlChannel(data)
                   if(res){
                      this.addLocaCtrPwd(this.devInfo.devId,data.ctrlPwd)
                       setTimeout(()=>{
                            // item.channelStatus = onOrOff
                            this.reqIotDeviceControlList()
                            this.perLoading = false 
                
                        },1000)
                      
                   }else{
                      this.erroStatus = true
                     
                   }
               
            },
            handleActiveDev(devId){
               let result = ''
               this.menus.some(item => {
                    if(item.devs && item.devs.length){
                       return item.devs.some(val => {
                             if(val.devId === devId){
                                 result = val
                                 return true
                             }
                         })
                    }
               })
         
               return result
            },
            devNameSearch(data){
                this.query( ()=> {
                     this.$set(this.ctlFormInfo,'name',data || '')
                     this.reqIotDeviceControlList() 
                } )
                
            },
            clearSearch(){
                this.$set(this.ctlFormInfo,'name','')   
                this.reqIotDeviceControlList()                
            },
        }
    }
</script>

<style lang="scss" scoped>
 .control_main_box{
    .right_content_box{
       border-radius: 8px;
       box-sizing: border-box;
       padding:0 20px;
       .tools_box{
            display: flex;
            align-items: center;
            padding: 20px 0;
            & > div:nth-child(1){
                display: flex;
                align-items: center;
                width: 670px;
                
            }
            & > div:nth-child(2){
                flex: 1;
                // margin-left: 180px;
                font-size: 18px;
                display: flex;
                align-items: center;
                & > span:first-child{
                    font-size: 30px;
                    margin-right: 8px;
                }
                .alert_box{
                    width: 220px;
                    height: 40px;
                    margin-left: 10px;
                }

            }
            & > div:nth-child(3){
                font-size: 16px;
                display: flex;
                align-items: center;
                & > span:first-child{
                    font-size: 24px;
                    display: inline-block;
                    margin-right: 6px;
                }   
            }
            .devSearchIpt{
                width: 210px;
                margin-right: 15px;
            }
            .devSearchIpt ::v-deep .el-input__inner{
                background-color: transparent;
                border: 1px solid rgba(#00C395, 0.4);
            }
       }
       .ctl_dev_box{
          width: 100%;
          height: calc(100% - 92px);
          border-radius: 8px; 
          padding: 20px;
          box-sizing: border-box;
          background-image: url('../../../assets/imgs/control/defalut/decoration_bg.png');
          background-repeat: no-repeat;
          background-size: contain;
          background-position: 50% 50%;
          & > ul{
              width: 100%;
              height: 100%;
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
              align-content: flex-start;
              & > li {
                  width: 290px;
                  height: 200px;
                  border-radius: 18px;
                  border: 1px solid transparent;
                  .top_box{
                      color: #fff;
                      padding: 7px 15px;
                      box-sizing: border-box;
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      font-size: 14px;
                      border-radius: 18px 18px 0 0;
                      border-bottom: 1px solid #15232D;
                      
                      & > div:first-child{
                          font-size: 16px;
                          display: flex;
                          align-items: center;
                          & > span:first-child{
                              display: block;
                              width: 8px;
                              height: 8px;
                              border-radius: 4px;
                              margin-right: 5px;
                              background: #999;
                          }
                          .awaitState{
                            background: #ffa800;
                          }
                          .closeState{
                              background: #999999;
                          }
                      }
                      & > div:last-child{
                          font-size: 18px;
                      }
                  }
                  .content_box{
                      width: 100%;
                      height: calc(100% - 36px);
                      border-radius: 0 0 18px 18px;
                      display: flex;
                      flex-direction: column;
                    //   padding: 15px 5px 15px 15px;
                      box-sizing: border-box;
                      & > div:first-child {
                          flex: 1;
                          display: flex;
                          align-items: center;
                          justify-content: center;
                          & >span{
                             font-size: 94px;
                             color: #999;
                          }
                          & > img {
                              width: 94px;
                            //   height: 88px;
                          }
                      }
                      & > div:last-child{
                          width: 100%;
                          height: 40px;
                          font-size: 14px;
                          display: flex;
                          border-top: 1px solid #15232D;
                          & > div {
                              flex: 1;
                              height: 100%;
                              color: #ccc;
                              text-align: center;
                              line-height: 40px;
                              transition: background-color 1s;
                          }
                          .stop_box{
                              border-left: 1px solid #15232D;
                              border-right: 1px solid #15232D;
                          }
                          .activeText{
                              color: #fff;
                          }
                          & > div:first-child{
                              border-radius: 0 0 0 15px;
                          }
                          & > div:last-child{
                              border-radius: 0 0 15px 0;
                          }
                        //   .stop_but_box{
                        //      height: 20px;
                        //      line-height: 20px;
                        //      border-radius: 10px;
                        //      padding: 0 10px;
                        //      margin-bottom: 12px;
                        //      font-size: 12px;
                        //      border: 1px solid #fff;
                        //   }
                        //   .baseState{
                        //       background-color: rgba(#ffa800, .2);
                        //       border: 1px solid #ffa800;
                        //   }

                
                      }
                  }
                 
              }
              & > li:nth-child(n + 6){
                     margin-top: 18px;
              }
              .supplyBox{
                    background-color: transparent;
              }
              
          }
       }
    }
    
 }
</style>