<template>
    <div class="autoCtl_box">
        <div class="tabs_header_box">
            <ul>
              <li 
               :class="activeCode == item.code? 'tabActive': ''"
               @click="navChange(item.code)"
               v-for="item of navList" 
               :key="item.code">{{ item.name }}</li>
            </ul> 
        </div>
        <div class="content_box">
          <component 
            :is="activeCode" 
            :devInfo ='info' 
            :linkageInfo='linkageInfo' 
            :planInfo='planInfo' ></component>
        </div>
    </div>
</template>

<script>
    import timeFrom from './timeFrom.vue'
    import linkageFrom from './linkageFrom.vue'
    export default {
        components:{
            timeFrom,
            linkageFrom
        },
        props:{
           info:{
               type: Object,
               default: () => {
                   return {}
               }
           }
        },
        data(){
            return{
                activeName: 'first',
                navList:[
                    {
                        name:'定时控制',
                        code: 'timeFrom'
                    },
                    {
                        name: '联动控制',
                        code: 'linkageFrom'
                    }
                ],
                activeCode: 'timeFrom',
                linkageInfo: null,
                planInfo: null,
            }
        },
        created(){
           this.reqIotActuatorControlDetail()
        },
        methods:{
            navChange(code){
                this.activeCode = code
            },
            async reqIotActuatorControlDetail(){
                 const res = await this.$api.IotActuatorControlDetail({id: this.info.id })
                 if(res){
                     let { planDetail, linkageDetail } = res
                     if(!!!planDetail && !!linkageDetail){
                         this.activeCode = 'linkageFrom'
                     }
                     this.linkageInfo = linkageDetail
                     this.planInfo = planDetail
                 }
            }
        }
    }
</script>

<style lang="scss" scoped>
 .autoCtl_box{
     width: 100%;
     padding: 0 10px;
     box-sizing: border-box;
     .tabs_header_box{
         width: 100%;
         height: 44px;
         & > ul{
             width: 100%;
             height: 100%;
             line-height: 44px;
             text-align: center;
             border-bottom: 2px solid #202E37;
             font-size: 16px;
             display: flex;
             & > li:not(:first-child) {
                 margin-left: 25px;
             }
         }
     }
     .content_box{
         box-sizing: border-box;
         padding: 20px 0px 10px 0px;
     }
 }
</style>