<template>
    <div class="linkageFrom">
        <el-form :model="ctlForm" :rules="rules" ref="ctlForm" label-width="100px" size="small" class="demo-ruleForm">
            <el-form-item label="关联设备" prop="collectorSensorArr">
                <el-cascader
                style="width: 100%"
                v-model="ctlForm.collectorSensorArr"
                :options="devSelectOptions"
                @change="sensorChange"></el-cascader>
            </el-form-item>
            <el-form-item label="有效期" prop="time">
                    <el-date-picker
                    v-model="ctlForm.time"
                    type="daterange"
                    align="right"
                    value-format="yyyy-MM-dd"
                    format="yyyy 年 MM 月 dd 日"
                    unlink-panels
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    style="width: 100%"
                    >
                    </el-date-picker>
            </el-form-item>
            <el-form-item label="有效时间段" prop='timeSlot'>
                <!-- <week v-model="ctlForm.week"></week> -->
                <div class="termTime_box">
                   <el-time-picker
                        v-model="ctlForm.timeSlot"
                        value-format="HH:mm"
                        format="HH:mm"
                        style="width:100%"
                        range-separator="至"
                        start-placeholder="开始时间"
                        end-placeholder="结束时间"
                        placeholder="选择时间范围"
                        is-range
                    >
                    </el-time-picker>
                   <el-button type="primary" @click="allDay" size="mini" class="allDay_but" plain icon="el-icon-check">全天</el-button>
                </div>
                
            </el-form-item>
          
                <el-form-item label="执行条件" prop='term'>
                   <termInput v-model="ctlForm.term" :unit='paramUnit'></termInput>
                </el-form-item>
          
                <el-form-item label="执行动作" prop="controlAction">
                    <el-radio-group v-model="ctlForm.controlAction">
                        <template v-if="isReversible">
                        <el-radio :label="2">关</el-radio>
                        <el-radio :label="1">开</el-radio>
                        <el-radio :label="0">暂停</el-radio>
                        </template>
                        <template v-else>
                        <el-radio :label="0">关</el-radio>
                        <el-radio :label="1">开</el-radio>
                        </template>
                        
                    </el-radio-group>
                </el-form-item>
          
             
        
            <el-row>
              <el-col :span="10">
                  <el-form-item label="延时操作">
                        <el-input
                            type="number"
                            class="input_box"
                            v-model.number="ctlForm.delay">
                            <span slot="suffix" class="unit_box">分钟</span>
                        </el-input>
                    </el-form-item>
              </el-col>
              <el-col :span="14">
                   <el-form-item label-width= '10px'>
                        <el-radio-group v-model="ctlForm.delayAction" class="delay_radio">
                            <template v-if="isReversible">
                                <el-radio :label="2">关</el-radio>
                                <el-radio :label="1">开</el-radio>
                                <el-radio :label="0">暂停</el-radio>
                            </template>
                            <template v-else>
                                <el-radio :label="0">关</el-radio>
                                <el-radio :label="1">开</el-radio>
                            </template>
                        </el-radio-group>
                        <el-button size="mini" type="primary" @click="delayDel">取消</el-button>
                    </el-form-item>
              </el-col>
            </el-row>
            <el-row>
            
              <el-col :span="24">
                     <el-form-item label="状态">
                        <el-switch
                        v-model="ctlForm.status"
                        :active-value="1"
                        :inactive-value="2"
                        active-color="#00C395"
                        inactive-color="#343F48">
                        </el-switch>
                        <span :class="['switch_text',ctlForm.status == 1 ? 'baseColor':'']">{{ ctlForm.status == 1 ? '启用':'停用' }}</span>
                    </el-form-item> 
              </el-col>
            </el-row>
            
            
            
           
           
            <el-form-item class="but_box">
                <el-button type="warning" plain @click="resetForm('ctlForm')" size="mini" icon="el-icon-close">取消</el-button>
                <el-button type="primary" plain @click="submitForm('ctlForm')" size="mini" icon="el-icon-check">保存</el-button>
            </el-form-item> 
        </el-form> 
        <HnSyncLoading
            :loading="perLoading" 
            :erroStatus='erroStatus'
             width= '80'
             types="line">
        </HnSyncLoading>
    </div>
</template>

<script>
    import cronMixin from './inputCron/cronMixin'
    import termInput from './inputCron/termInput.vue'
    import { deepClone , devCodeAndId } from '@/libs/util'
    export default {
        name: 'paramFrom',
        mixins: [cronMixin],
        components:{
           termInput,
        },
        props:{
          devInfo: Object,
          linkageInfo: Object,
        },
        data(){
            const arrayEmpty = (rule, value, callback) => {
                 if(!Array.isArray(value)) callback(new Error('数据格式为数组'))
                 if(value.length){
                    callback()
                 }else{
                     callback(new Error('请选择日期'))
                 }
            }
            return{
                devSelectOptions:[],
                erroStatus: false,
                perLoading: false,
                ctlForm:{
                    id:'',
                    term: '',  // 执行条件
                    timeSlot: ['00:00','23:59'],
                    time: null,
                    eTime:'',
                    sTime:'',
                    controlAction:'',  // 执行动作
                    value:"",          // 值
                    actuatorIds:'',
                    collectorSensorArr: null,
                    delayAction:'',
                    delay: null,
                    delayUnit: '2',
                    beginTime:'',
                    endTime:'',
                    status: '',
                    symbols:'',        // 运算符
                    collectorSensorId: '' //传感器参数
                },
                rules:{
                    time: [
                    { required: true, message: '请输入有效期', trigger: 'blur' },
                    ],
                    // week: [
                    // { validator: arrayEmpty, trigger: 'blur', required: true}
                    
                    // ],
                    collectorSensorArr: [
                        { required: true, message: '请选择传感器参数', trigger: 'blur' }, 
                    ],
                    term: [
                       { required: true, message: '请选择执行条件', trigger: 'blur' }, 
                    ],
                    // controlAction: [
                    //     { required: true, message: '请选择执行动作', trigger: 'blur' },
                    // ]
                },
                paramUnit: '',
            }
        },
        watch:{
         
           linkageInfo(val){
               if(val) this.analysisData(val)
           }
        },
        created(){
            if(this.linkageInfo) this.analysisData(this.linkageInfo)
            this.reqIotDevSensorChooseList()
        },
        computed:{
            isReversible(){
                return this.devInfo && this.devInfo.isReversible ? true : false
            },
            isEdit(){
                  return !!this.linkageInfo
            },

        },
        methods:{
            // 解析数据
            analysisData(val){
                  let { eTime,sTime } = val
                  Object.assign(this.ctlForm,val)
                  //  日期显示
                   let eT =  eTime.slice(0,eTime.indexOf(' ')) 
                   let sT =  sTime.slice(0,sTime.indexOf(' ')) 
                   this.$set(this.ctlForm,'time',[sT,eT])
                 // 时间段处理
                 let beginTime =  this.slotTimeHanlde(val.beginTime),
                     endTime = this.slotTimeHanlde(val.endTime);
                 this.$set(this.ctlForm,'timeSlot',[beginTime,endTime])     
                //  控制条件
                let termInfo = {
                    devId: val.symbols,
                    devCode: val.value
                }
                let termStr = devCodeAndId(termInfo)
                 this.$set(this.ctlForm,'term',termStr)     
            },
            // 整合数据
            mergeData(){
                let ctlForm = this.ctlForm
                let cloneData = deepClone(ctlForm)
                // 转化延时时间为数字
                // 处理开始结束日期
                let timeArr = ctlForm.time
                cloneData.sTime = timeArr[0]
                cloneData.eTime = timeArr[1]
                // 处理时间段
                if(ctlForm.timeSlot && ctlForm.timeSlot.length){
                   cloneData.beginTime= ctlForm.timeSlot[0]
                   cloneData.endTime= ctlForm.timeSlot[1]
                }
                //  执行器id 赋值
                cloneData.actuatorIds = [this.devInfo.id]  
                // 赋值操作条件
                let termInfo = devCodeAndId(ctlForm.term)
                cloneData.symbols = parseInt(termInfo.devId)
                cloneData.value = parseInt(termInfo.devCode)
                // 处理传感器
                cloneData.collectorSensorId = ctlForm.collectorSensorArr[1]
                return cloneData
            },
            slotTimeHanlde(data){
               if(data.includes(':')){
                  let strArr = data.split(":")
                  return `${strArr[0]}:${strArr[1]}`
               }
            },
            resetForm(formName) {
                this.$refs[formName].resetFields();
                this.devInfo.visbise= false
            },
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                if (valid) {
                   
                  let data = this.mergeData()
                  if(this.isEdit){
                    //   data.planId= this.planInfo.id
                      this.reqEditIotControlLinkage(data)
                     
                  }else{
                     this.reqAddIotControlLinkage(data)
                  }
                  
                 
                } else {
                    
                    return false;
                }
                });
            },
         
            // 新增
            async reqAddIotControlLinkage(data){
                this.perLoading = true
                const res = await this.$api.AddIotControlLinkage(data)
                if(res){
                   setTimeout(()=>{
                        this.perLoading = false
                        this.devInfo.visbise= false
                   },1000)
                }else{
                    this.erroStatus = true
                }
            },
            // 编辑
            async reqEditIotControlLinkage(data){
                this.perLoading = true
                const res = await this.$api.EditIotControlLinkage(data)
                if(res){
                   setTimeout(()=>{
                        this.perLoading = false
                        this.devInfo.visbise= false
                   },1000)
                }else{
                    this.erroStatus = true
                }
            },
            // 采集参数列表
            async reqIotDevSensorChooseList(){
                 const res = await this.$api.IotDevSensorChooseList()
                 if(res && res.length){
                     let result = []
                     let linkageInfo = this.linkageInfo
                     let collectorSensorId = linkageInfo && linkageInfo.collectorSensorId ? linkageInfo.collectorSensorId : ''

                     res.forEach(item => {
                          let arrList = {
                              value: item.devId,
                              label: item.devName,
                              children: []
                          }
                          
                          if(item.sensors && item.sensors.length){
                              item.sensors.forEach(ele => {
                                   if(collectorSensorId &&  ele.id == collectorSensorId){
                                        this.$set(this.ctlForm,'collectorSensorArr',[item.devId,collectorSensorId])
                                        this.paramUnit = ele.enUnit
                                   }
                                    
                                   
                                   arrList.children.push({
                                       value: ele.id,
                                       label: ele.name,
                                       unit: ele.enUnit
                                   })
                              })
                          }else{
                              arrList.children= null
                          }
                          result.push(arrList)
                     })

                    this.devSelectOptions = result
                 }
            },
            delayDel(){
                this.$set(this.ctlForm,'delayAction','')
                this.$set(this.ctlForm,'delay', null) 
            },
            allDay(){
                this.$set(this.ctlForm,'timeSlot',['00:00','23:59'])
            },
            sensorChange(data){
              
               if(data && data.length){

                   let [ paretId, childId] = data
                   this.devSelectOptions.some(item => {
                      
                         if(item.value == paretId){
                              
                              let childrenInfo = item.children
                               
                              if(childrenInfo && childrenInfo.length){
                                
                                 return childrenInfo.some(ele => {
                                        
                                       if(ele.value == childId){
                                           this.paramUnit = ele.unit
                                           return true
                                       }
                                   })
                              }
                         }
                    }
                   )
                
               }
            }
        }
        
    }
</script>

<style lang="scss" scoped>
 .linkageFrom{
    box-sizing: border-box;
    padding-right: 20px;
   .demo-ruleForm{
        width: 100%;
        .but_box{
            text-align: right;
            margin-bottom: 0 !important;
        }
        .termTime_box{
            display: flex;
            align-items: center;
            .allDay_but{
                margin-left: 10px;
            }
        }
    
    }
    .switch_text{
     margin-left: 12px;
     color: #999;
   }
   .input_box ::v-deep .el-input__inner{
         text-align: center;
    }
    .unit_box{
         margin-right: 5px;
     }
     .el-radio-group ::v-deep .el-radio{
       margin-right: 20px !important;
     }
 }
</style>