<template>
   <el-dialog 
     custom-class="hn-dialog" 
     class="custom-dialog-wrapper" 
     :width="width" 
     :title="title" 
     :visible.sync="isShow"   
     :close-on-click-modal= 'false'
     :modal-append-to-body= 'false'
     >
    <div class="dialog-body">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
            <el-form-item label="控制密码" prop="pwd">
                <el-input v-model="ruleForm.pwd" type="password"></el-input>
            </el-form-item>
             <el-form-item class="but_box">
                 <el-button class="butbox" size="small" @click="resetForm('ruleForm')" round>取消</el-button>
                 <el-button class="butbox" type="primary" size="small" @click="submitForm('ruleForm')" round>确定</el-button>
            </el-form-item>
      </el-form>
    </div>    
  </el-dialog>
</template>

<script>
    export default {
        props:{
            width: {
              type: String,
              default: "20%",
            },
            title: {
              type: String,
              default: "控制密码",
            },
            isShow: {
                type: Boolean,
                default: false
            },
            ctlData: {
                type: Object,
                default:()=> { return {}}
            }
        },
        data(){
            return {
                ruleForm:{
                    pwd: ''
                },
                rules:{
                    pwd: [
                        { required: true, message: '请输入控制密码', trigger: 'blur' },
                    ],
                }
            }
        },
        methods:{
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                       this.$emit('update:isShow',false)
                       let data = Object.assign({ ctrlPwd: this.ruleForm.pwd },this.ctlData)
                       this.$emit('onOk',data)
                       this.$refs[formName].resetFields();
                    } else {
                  
                        return false;
                    }
                });
            },
            resetForm(formName) {
                this.$refs[formName].resetFields();
                this.$emit('update:isShow',false)
            }
        }
    }
</script>

<style lang="scss" scoped>
/*垂直居中 */
.custom-dialog-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  
  
}
.custom-dialog-wrapper ::v-deep .hn-dialog {
    max-height: calc(100% - 30px);
    max-width: calc(100% - 30px);
    margin: 0 !important;
    .el-dialog__title{
      color: #fff;
    }
  }
// .custom-dialog-wrapper ::v-deep 
.but_box{
    text-align: right;
    .butbox{
        width: 70px;
    }
}
</style>